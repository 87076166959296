/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import authentication from "../../msalb2creact";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import NIFInput from "./NIFInput";
import AdicionaMenorFeedbackPopup from "./AdicionaMenorFeedbackPopup";
import ContextConstructor from "../../components/Context/Context";

//import RegistrationSucess from '../../components/RepresentativeResponse/RegistrationSucess';
//import RegistrationError from '../../components/RepresentativeResponse/RegistrationError';

/* MODULE IMPORTS */
import React, { Component } from "react";

/* CSS IMPORT */
import "./AdicionaMenor.css";

const browserHistory = createBrowserHistory({ basename: "Adiciona Menor Page" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
        instrumentationKey: "a20b3d0d-87b9-41e6-bcde-892a425215fe",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

class AdicionaMenor extends Component {
  static displayName = AdicionaMenor.name;

  constructor(props) {
    super(props);
    this.state = {
      adicionamenor: [],
      error: [],
      loading: true,
      hasAgreed: true,
      checked: false,
      // nifs: [],
      nif: "",
      setnifId: "",
      inputs: [
        {
          id: 1,
          input: <NIFInput key={0} id={1} removeNif={this.removeNif} />,
          isDeletable: false,
        },
      ],
      associate: "",
      allNifs: [],
      hasInvalidNifs: false,
    };

    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.addNif = this.addNif.bind(this);
    this.removeNif = this.removeNif.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
  }

  async componentDidMount() {
    var idToken = this.context.idToken;

    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await fetch("/Profile/GetProfile", { headers });
    //const data = await response.json();

    if (response.status != "200") {
      this.setState({ error: true, loading: false });
      if (response.status === 204)
        appInsights.trackException({ exception: "Profile no content", severityLevel: 3 });
    } else {
      this.setState({ error: false, loading: false });
    }
  }

  static errorLoadingPortfolio() {
    return (
      <div>
        <ErrorPage />
      </div>
    );
  }

  handleCheckBoxChange(event) {
    this.setState({ checked: event.target.checked });
  }
  handleSubmit(event) {
    alert("A name was submitted: ");
    event.preventDefault();
  }

  handleCreate = async () => {
    const nifElements = document.getElementsByClassName("menor-nif");
    const nifs = [...nifElements].map((element) => element.value);
    var idToken = this.context.idToken;

    const headers = {
      Authorization: `Bearer ${idToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await fetch(`/Profile/CreateMymAssociateConnection`, {
      headers,
      method: "POST",
      body: JSON.stringify({
        nifs: nifs,
      }),
    });
    //console.log(response);
    const data = await response.json();

    //console.log("Adicionar menor data ", data);

    const validNifs = data.validNifs.map((nif) => ({
      id: nif,
      text: "NIF",
      number: nif,
      source: require("../../images/CheckCircle.png"),
    }));

    const invalidNifs = data.invalidNifs.map((nif) => ({
      id: nif,
      text: "NIF",
      number: nif,
      source: require("../../images/alertaCircle.png"),
    }));

    const allNifs = [...validNifs, ...invalidNifs];

    this.setState({ allNifs: allNifs, hasInvalidNifs: invalidNifs.length > 0 });
  };

  addNif() {
    var count = this.state.inputs.length;
    this.setState((prevState) => ({
      inputs: [
        ...prevState.inputs,
        {
          id: count + 1,
          input: <NIFInput id={count + 1} key={count + 1} removeNif={this.removeNif} />,
          isDeletable: true,
        },
      ],
    }));
  }

  removeNif(id) {
    this.setState((prevState) => ({
      inputs: prevState.inputs.filter((item) => item.id !== id),
    })).bind(this);
  }

  static renderAdicionaMenorData(checked, handleCheckBoxChange, inputs, addNif, handleCreate) {
    return (
      <div className="main">
        <div className="Title">Adicionar Criança ou Jovem Associado</div>
        <div className="Associate-Fields">
          <label>
            Para consultar a posição das modalidades subscritas pelas suas crianças ou jovens, por
            favor insira o respetivo Número de Identificação Fiscal (NIF)
          </label>
        </div>
        <br />
        <form>
          <form classname="Associate-Fields">
            <div classname="Associate-Same-Line-Fields">
              {inputs.map((item, i) => item.input)}
              <br />
              <br />
              <div class="add-nif-btn" onClick={addNif} style={{ cursor: "pointer" }}>
                <img
                  src={require("../../images/add_person.png")}
                  alt="Botão de adicionar NIF"
                  height="20"
                  width="20"
                  style={{ marginRight: "10px" }}
                />
                <div>Adicionar outro NIF</div>
              </div>
            </div>
          </form>
          <br />
          <br />
          <div class="Associate-Same-Line-Fields">
            <label class="container">
              <input
                onClick={handleCheckBoxChange}
                checked={checked}
                style={{ defaultChecked: checked }}
                type="checkbox"
                class="form-check-input"
                id="menorCheckBox"
              />
              <span class="checkmark"></span>
              Confirmo que a informação inscrita se destina, apenas, à utilização de My Montepio.
            </label>
          </div>
        </form>
        <div className="Associate-Fields">
          <p>
            <button type="button" disabled={!checked} onClick={handleCreate}>
              Avançar
            </button>
          </p>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <div className="main-page-loading">
        <div className="loading">
          <img src={require("../../images/loading.svg")} alt="carregamento"></img>
        </div>
      </div>
    ) : this.state.error ? (
      AdicionaMenor.errorLoadingPortfolio()
    ) : this.state.allNifs.length > 0 ? (
      <AdicionaMenorFeedbackPopup
        infoList={this.state.allNifs}
        message={
          this.state.hasInvalidNifs
            ? "Dados inválidos. Por favor contacte o seu Gestor Mutualista ou a Linha de Apoio através do Tel. 213 248 112 (atendimento personalizado, dias úteis das 09h às 21h. Custo de chamada para a rede fixa nacional)."
            : "Registo efetuado com sucesso. Já pode consultar a posição das modalidades subscritas pela criança ou jovem."
        }
      />
    ) : (
      AdicionaMenor.renderAdicionaMenorData(
        this.state.checked,
        this.handleCheckBoxChange,
        this.state.inputs,
        this.addNif,
        this.handleCreate,
      )
    );

    return <div>{contents}</div>;
  }
}

AdicionaMenor.contextType = ContextConstructor;

export default withAITracking(reactPlugin, AdicionaMenor, AdicionaMenorFeedbackPopup);
