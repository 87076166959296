/* COMPONENT IMPORTS*/
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import { BenefitsSection } from "../../components/Home/BenefitsSection";
import BenefitsPie from "../../components/BenefitsPie/BenefitsPie";
import { SavingsCard } from "../../components/Home/SavingsCard";
import { ProtectionSavingsCard } from "../../components/Home/ProtectionSavingsCard";
import { Loading } from "../../components/Loading/Loading";
import NotificationBanner from "../../components/Notifications/NotificationBanner";
import ContextConstructor from "../../components/Context/Context";
import { LandingPage } from "../../components/HomeNoAssociate/LandingPage";
import { NotAssociateHome } from "../../components/HomeNoAssociate/NotAssociateHome";

/* MODULE IMPORTS */
import { NavLink } from "react-router-dom";
import React, { Component } from "react";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import { ApplicationInsights, PageView } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

/* CSS IMPORT */
import "./Home.css";
import "pure-react-carousel/dist/react-carousel.es.css";

const pageid = "14595";
const browserHistory = createBrowserHistory({ basename: "Home Page" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
        instrumentationKey: "a20b3d0d-87b9-41e6-bcde-892a425215fe",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.trackPageView({ name: "Home" });
appInsights.loadAppInsights();

class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = {
      portfolioData: [],
      benefitData: [],
      error: [],
      loadingB: true,
      loadingP: true,
      failure: false,
      benefits: [],
      toggleActive: true,
      active: "aTab",
      hasBenefits: false,
      DateString: [],
      DateStringValue: "",
      DateStart: new Date(),
      DateEnd: new Date(),
      DateStartCalendar: new Date(),
      DateEndCalendar: new Date(),
      Sort: "1",
      filtered: [],
      select2: undefined,
      sortSelect: "Maior Poupança",
    };
  }

  componentDidMount() {
    // console.log("HOME Context: ", this.context);

    //console.log("HOME ContextselectedUserID: ", this.context.selectedUserID)

    //Only run when context is defined
    if (
      this.context.selectedUserID !== "" &&
      this.context.selectedUserID !== undefined &&
      this.context.selectedUserID !== null
    ) {
      this.populatePortfolioData();
      this.populateBenefitsData();
    }

    this.getScrollPositionForCss();
  }

  //componentDidUpdate() {
  //    this.populatePortfolioData();
  //    this.populateBenefitsData();
  //    this.getScrollPositionForCss();
  //}

  getScrollPositionForCss = () => {
    // The debounce function receives our function as a parameter
    const debounce = (fn) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      };
    };

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY;
    };

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });

    // Update scroll position for first time
    storeScroll();
  };

  renderDashboard() {
    return (
      <div>
        <div className="modalidades-home">
          <SavingsCard
            income={this.state.portfolioData.savings.totalAmounts.totalInvestedAmount}
            gain={this.state.portfolioData.savings.totalAmounts.totalReceivedAmount}
          />
          <ProtectionSavingsCard
            protectionCurrentAmount={
              this.state.portfolioData.protection.totalAmounts.totalCurrentAmount
            }
            protectionsavingsCurrentAmount={
              this.state.portfolioData.savingsPlusProtection.totalAmounts.totalCurrentAmount
            }
          />
        </div>
        <div className="carousel-wrapper">
          <CarouselProvider
            naturalSlideWidth={400}
            naturalSlideHeight={500}
            totalSlides={3}
            visibleSlides={1}>
            <Slider>
              <Slide index={0}>
                <SavingsCard
                  income={this.state.portfolioData.savings.totalAmounts.totalInvestedAmount}
                  gain={this.state.portfolioData.savings.totalAmounts.totalReceivedAmount}
                />
              </Slide>
              <Slide index={1}>
                <div className="poupanca">
                  <div className="title-home">Proteção</div>
                  <NavLink to="/protecao" style={{ textDecoration: "none" }}>
                    <div className="poupanca-card">
                      <img src={require("../../images/protecaopoupanca.svg")} alt=""></img>
                      <div className="poupanca-income">
                        {new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          this.state.portfolioData.savingsPlusProtection.totalAmounts
                            .totalCurrentAmount,
                        )}
                      </div>
                      <div className="poupanca-gain">Proteção+Poupança</div>
                      <div className="poupanca-description">Capital Subscrito</div>
                      <div className="subscribe">
                        <img
                          src={require("../../images/more.png")}
                          alt="Botão de subscrever"
                          width="60px"
                          height="60px"></img>
                        <div>Subscrever</div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Slide>
              <Slide index={2}>
                <div className="poupanca">
                  <div className="title-home">Proteção</div>
                  <NavLink to="/protecao" style={{ textDecoration: "none" }}>
                    <div className="poupanca-card">
                      <img src={require("../../images/protecao.svg")} alt=""></img>
                      <div className="poupanca-income">
                        {new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          this.state.portfolioData.protection.totalAmounts.totalCurrentAmount,
                        )}
                      </div>
                      <div className="poupanca-gain">Proteção</div>
                      <div className="poupanca-description">Capital Protegido</div>
                      <div className="subscribe">
                        <img
                          src={require("../../images/more.png")}
                          alt="Botão de subscrever"
                          width="60px"
                          height="60px"></img>
                        <div>Subscrever</div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Slide>
            </Slider>
            <DotGroup />
          </CarouselProvider>
        </div>
      </div>
    );
  }

  renderBenefitsDashboard() {
    var grafico =
      this.state.benefits.totalBenefits > 0 ? (
        <BenefitsPie
          data={this.state.benefits.benefitsByCategory}
          valorPoupado={this.state.benefits.totalBenefits}
        />
      ) : (
        <div>
          <img
            className="zero-benefits"
            src={require("../../images/zeroGraph.png")}
            alt="benefícios"></img>
        </div>
      );

    const pieChart = () => {
      if (this.state.toggleActive)
        return (
          <div className="pie-div pie-div-home">
            {grafico}
            <div className="title-benefits">
              Programa de
              <br />
              Benefícios
            </div>
            <NavLink to="/beneficios">
              <img
                className="plus-icon-orange"
                src={require("../../images/newseries.png")}
                to="/beneficios"
                alt="Botão de subscrever"
                width="60px"
                height="60px"></img>
              <div className="detail-benefits">Ver detalhe</div>
            </NavLink>
          </div>
        );
    };

    //console.log("valor do beneficio", this.state.benefits.totalBenefits);

    return <div>{pieChart()}</div>;
  }

  NotAssociateHomepage() {
    let entityWithMinors = this.context.mymUsers.length > 1 ? true : false;
    //let entityIsNotAssociate = (this.context.selectedUser.userState == "NOTASSOCIATE" || this.context.selectedUser.status == "NATIVO") ? true : false

    // console.log(
    //   "entityWithMinors: ",
    //   entityWithMinors /*, " entityIsNotAssociate: ", entityIsNotAssociate*/,
    // );

    if (entityWithMinors === false) {
      return <LandingPage />;
    } else {
      return <NotAssociateHome />;
    }
  }

  render() {
    const benefitsContent =
      this.state.loadingB || this.context.selectedUserID === "" ? (
        //if true loading state
        <Loading />
      ) : this.state.error || this.context.selectedUserID === "" ? (
        //in case of error
        <ErrorPage />
      ) : (
        this.renderBenefitsDashboard(this.state.benefitData)
      );

    let portfolioContent =
      this.state.loadingP || this.context.selectedUserID === "" ? (
        //if true loading state
        <Loading />
      ) : this.state.error || this.context.selectedUserID === "" ? (
        //in case of error
        <ErrorPage />
      ) : (
        // if loading false, load component
        this.renderDashboard(this.state.portfolioData)
      );

    let content =
      this.context.selectedUser.userState == "NOTASSOCIATE" ||
      this.context.selectedUser.status == "NATIVO" ? (
        <div className="main">
          <NotificationBanner />
          {this.NotAssociateHomepage()}
        </div>
      ) : (
        <div className="main">
          <NotificationBanner />
          {benefitsContent}
          {portfolioContent}
        </div>
      );

    //return (
    //    <div className="main" >
    //        <NotificationBanner />
    //        {/*{benefitsContent}*/}
    //        {/*{portfolioContent}*/}
    //        {this.NotAssociateHomepage()}
    //    </div>
    //);

    return <React.Fragment>{content}</React.Fragment>;
  }

  populatePortfolioData() {
    var clientId = this.context.selectedUserID;
    // console.log("HOME Portfolio client ", clientId);
    var idToken = this.context.idToken;
    const headers = {
      Authorization: `Bearer ${idToken}`,
      clientId: clientId,
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    };

    fetch(`/Portfolio/GetPortfolio`, {
      headers,
      method: "GET",
    })
      .then((res) => {
        //console.log("response portfolio (1) ", res);
        return res.json();
      })
      .then((response) => {
        //console.log("response portfolio (2) ",response);

        this.setState({
          portfolioData: response,
          error: false,
          loadingP: false,
        });
      })
      .catch((error) => {
        // console.log("error portfolio", error);

        this.setState({ error: true, loadingP: false });

        if (error.status != "200") {
          this.setState({
            error: true,
            loadingP: false,
          });
          if (error.status === 204) {
            appInsights.trackException({
              exception: "GetPortfolio no content",
              severityLevel: 3,
            });
          }
        }
      });
  }

  populateBenefitsData() {
    var clientId = this.context.selectedUserID;

    // console.log("HOME Benefits ClientId ", clientId);
    var idToken = this.context.idToken;

    const headers = {
      Authorization: `Bearer ${idToken}`,
      clientId: clientId,
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    };

    fetch(`/Benefits/GetBenefits`, {
      headers,
      method: "GET",
    })
      .then((res) => {
        // console.log("response Benefits: ", res);
        //try {
        //    const data = JSON.stringify(res);
        //    return data;

        //} catch (e) {
        //    console.log("error ", e);
        //}
        return res.json();
      })
      .then((response) => {
        //console.log("response Benefits(2): ", response);

        let DateString = "";
        if (response.benefitsAll.length > 0) {
          DateString =
            new Intl.DateTimeFormat("pt-PT").format(
              new Date(response.benefitsAll[0].transactionDate),
            ) +
            " - " +
            new Intl.DateTimeFormat("pt-PT").format(new Date());
        }

        this.setState({
          benefits: response,
          DateString: DateString,
          error: false,
          loadingB: false,
        });

        if (response.totalBenefits == 0) {
          this.setState({
            hasBenefits: false,
          });
        } else {
          this.setState({
            hasBenefits: true,
          });
        }
      })
      .catch((error) => {
        // console.log("error beneftis ", error);

        this.setState({ error: true, loadingB: false });

        if (error.status === 204) {
          appInsights.trackException({
            exception: "Benefits no content",
            severityLevel: 3,
          });
        }
      });
  }
}

Home.contextType = ContextConstructor;

export default withAITracking(reactPlugin, Home);
